<template>
  <v-container
    id="settings-form"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card icon="mdi-cog">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('settings.settings') }}
              <span class="body-1">— {{ $t('settings.config') }}</span>
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="form.production_leadtime"
                    :rules="rules.productionLeadMin"
                    type="number"
                    :label="$t('settings.max_lead_time')"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <span>{{ $t('settings.needs_production_badge') }}"</span>
                  <v-color-picker
                    v-model="form.should_be_in_production_label_color"
                    hide-inputs
                    show-swatches
                    hide-canvas
                    label="Moet nu in productie badge kleur"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <span>{{ $t('settings.new_order_badge') }}</span>
                  <v-color-picker
                    v-model="form.new_order_label_color"
                    hide-inputs
                    show-swatches
                    hide-canvas
                    label="Nieuwe order badge kleur"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <span>{{ $t('settings.in_production_badge') }}</span>
                  <v-color-picker
                    v-model="form.in_production_label_color"
                    hide-inputs
                    show-swatches
                    hide-canvas
                    label="In productie badge kleur"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    :disabled="isFormInValid"
                    color="success"
                    class="mr-0"
                    :loading="loading"
                    @click="save"
                  >
                    Opslaan
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          icon="mdi-printer"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('settings.printer_settings') }}
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="form.printer_ip_address"
                    type="text"
                    :label="$t('printer_ip')"
                    class="purple-input"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    @click="testConnection"
                  >
                    {{ $t('button.test_connection') }}
                  </v-btn>
                  <v-btn
                    :disabled="isFormInValid"
                    color="success"
                    class="mr-0"
                    :loading="loading"
                    @click="save"
                  >
                    Opslaan
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  {{ printerError }}
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Settings',
    data () {
      return {
        loading: false,
        form: {
          production_leadtime: null,
          printer_ip_address: null,
          new_order_label_color: null,
          in_production_label_color: null,
          should_be_in_production_label_color: null,
        },
        rules: {
          productionLeadMin: [
            value => value > 0 || 'Aantal minuten moet groter zijn dan 0',
          ],
        },
        printerError: null,
      }
    },
    computed: {
      isFormInValid () {
        return !(this.form.production_leadtime > 0) || Object.keys(this.form).some(key => this.form[key] === null)
      },
    },
    async beforeMount () {
      let formFields = this.$store.state.application.configuration
      if (formFields === null) {
        await this.$store
          .dispatch('application/getConfiguration')
          .then(response => {
            this.$store.commit('application/configuration', response.data)
            formFields = this.$store.state.application.configuration
          })
          .catch(response => {
            this.$store.commit('viewstate/notify', {
              color: 'error',
              text:
                'Configuratie kon niet opgehaald worden! Herlaad de pagina...',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
      Object.keys(this.form).forEach(key => {
        this.form[key] = formFields[key]
      })
    },
    methods: {
      save (e) {
        e.preventDefault()

        this.loading = true
        this.$store.commit('viewstate/loader', {
          title: 'Instellingen worden opgeslagen...',
          status: 'loading',
        })
        this.$store
          .dispatch('application/putConfiguration', this.form)
          .then(response => {
            if (response) {
              this.$store.commit('application/configuration', response.data)
              this.$store.commit('viewstate/loader', {
                title: 'Succesvol opgeslagen',
                status: 'succeeded',
              })
            } else {
              this.$store.commit('viewstate/loader', {
                title: 'Aanpassingen konden niet verwerkt worden!',
                status: 'failed',
              })
            }
          })
          .catch(hasFailed => {
            this.$store.commit('viewstate/notify', {
              color: 'error',
              text:
                'Het verzoek kon niet verwerkt worden. Probeer het later opnieuw...',
            })
          })
          .finally(() => {
            this.loading = false
          })
      },
      testConnection () {
        var printerIp = this.form.printer_ip_address
        var xhttp = new XMLHttpRequest()
        try {
          xhttp.open('POST', 'https://' + printerIp + '/StarWebPRNT/SendMessage', true)
          xhttp.setRequestHeader('Content-Type', 'text/xml; charset=UTF-8')
          xhttp.send('')
          xhttp.onreadystatechange = function () {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
              this.printerError = 'Success'
            }
          }
          xhttp.onerror = function (e) {
            this.printerError = 'error'
          }
        } catch (e) {
          if (e.message.includes('Invalid URL')) {
            this.printerError = 'Error2'
          }
        }
      },
    },
  }
</script>
